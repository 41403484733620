export default {
  Name: 'PlanType',
  props: {
    plan: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
}
